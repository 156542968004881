import { alternateRedirectToParam, redirectToParam } from '../constants';

// @ts-check
export const getPlatform = (
  locationSearch = window.location.search
) => {
  const defaultPlatform = 'USCCA';
  const urlParams = new URLSearchParams(locationSearch);
  const redirectToParamValue = urlParams.get(redirectToParam) || urlParams.get(alternateRedirectToParam);

  if (redirectToParamValue) {
    const urlCheckFuncs = [
      () => ['academy-qualifications.', 'academy.'].some(e => redirectToParamValue.includes(e)) ?
        'Protector Academy' : null,
      () => redirectToParamValue.includes('checkout.') ? 'Checkout' : null,
      () => redirectToParamValue.includes('/resources/ccw_reciprocity_map') ? 'Reciprocity' : null,
      () => redirectToParamValue.includes('deltadefense.com') ? 'Delta Defense' : null,
      () => redirectToParamValue.includes('my.') ? 'MyUSCCA' : null,
      () => redirectToParamValue.includes('/blog') ? 'Blog' : null
    ];
    return urlCheckFuncs.find(u => !!u())?.() || defaultPlatform;
  } else {
    return defaultPlatform;
  }
};
